import styled from 'styled-components';

export const ColumnSeparator = styled.div`
  color: rgba(224, 224, 224, 1);
  right: -12px;
  display: flex;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  min-height: 56px;
  opacity: 1;
  top: 0;

  .MuiSvgIcon-root {
    width: 1.1em;
  }
`;
