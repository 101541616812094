import styled from 'styled-components';

export const EntradaItensContainer = styled.div`
  /* padding: 20px; */
  padding-right: 12px;
  /* padding-bottom: 12px; */
  .bg-green {
    background-color: #b3f7b3;
    padding-bottom: 5px;
  }
  .bg-blue {
    background-color: #c4e9ed;
    padding-bottom: 5px;
  }

  .css-64pf72-Field {
    margin-top: 0px;
  }

  .itens-container {
    display: grid;
    width: 100%;
    background: #c4e9ed;
    gap: 5px;
    margin: 0;
    padding: 10px;
    grid-template-columns: repeat(7, 1fr);
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 572px) {
      grid-template-columns: 1fr;
    }
  }
`;
